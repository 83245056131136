<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button
						type="text"
						size="small"
						@click="back"
						>返回</el-button>
				</p>
				<div class="h-b">
					<el-button
						type="primary"
						size="small"
						@click="add"
						v-right-code="'Tenantsystem:Save'"
						>新增</el-button
					>
				</div>
				<div class="h-b">
					<el-button
						type="text"
						size="small"
						@click="save"
						v-right-code="'Tenantsystem:Save'"
						>保存</el-button
					>
				</div>
			</div>
		</div>

		<div class="form-list">
			<el-form :model="dataSource" ref="basicForm" :rules="baseCheckRule">
                <el-form-item>
                    <el-col :span="3" class="form-title" ><span style="color: red">*</span> 服务器名称：</el-col>
                    <el-col :span="9">
                        <el-form-item prop="Name">
                            <el-input v-model="dataSource.Name" :maxlength="256" :minlength="2" placeholder="服务器名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color: red">*</span> 服务器IP地址：</el-col>
                    <el-col :span="9">
                        <el-form-item prop="Ip">
                            <el-input v-model="dataSource.Ip" :maxlength="500" :minlength="8" placeholder="服务器IP地址"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title" > 服务器IP端口：</el-col>
                    <el-col :span="9">
                        <el-form-item prop="Port">
                            <el-input-number
                                v-model="dataSource.Port" 
                                :min="-1" 
                                :max="65535" 
                                :controls="false" 
                                :placeholder="不输入使用默认值">
                            </el-input-number>
                        </el-form-item>
                    </el-col>
                    
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">用户名：</el-col>
                    <el-col :span="9">
                        <el-form-item prop="UserName">
                            <el-input v-model="dataSource.UserName" :maxlength="64" :minlength="2" :placeholder="用户名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title" > 密码：</el-col>
                    <el-col :span="9">
                        <el-form-item prop="UserPwd">
                            <el-input v-model="dataSource.UserPwd" :maxlength="64" :minlength="2" :placeholder="密码" show-password></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>

                <el-form-item>
                    <el-col :span="3" class="form-title"><span style="color: red">*</span> 中间件类型：</el-col>
                    <el-col :span="9">
                        <el-form-item prop="MiddlerwareType">
                            <DictionarySelect 
                                v-model="dataSource.MiddlerwareType" 
                                datType='Int' 
                                dicTypeCode="MiddlewareType">
                            </DictionarySelect>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3" class="form-title" > 是否默认：</el-col>
                    <el-col :span="9">
                        <el-form-item prop="IsDefault">
                            <el-checkbox :size="'medium'" v-model="dataSource.IsDefault"></el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item>
                    <el-col :span="3" class="form-title">备注：</el-col>
                    <el-col :span="21">
                        <el-form-item prop="ServerDesc">
                            <el-input type="textarea" v-model="dataSource.ServerDesc" rows="3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
    export default {
        data() {
            return {
                collapseItem: ['basicinfo','basicinfo1',''],
                baseCheckRule: {
                    Name:[{ required: true, message: '不能为空', trigger: 'blur' }],
                    Ip: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    MiddlerwareType: [{ required: true, message: '不能为空', trigger: 'change' }],
                },
                activeName:"tabbasic",
                dataSource:{
                },
                processStatusList:[],
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isAdd:false
            },
        },
        mounted: function () {
        },
        computed: {
            
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) 
                            this.initial(curVal); //初始化
                    }else{
                        this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            //重置表单
            resetForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            add: function () {
                this.initial();
                this.config.isAdd = true;
            },
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/tenantserver/get", "get", { id: id }, (data) => {
                    _this.dataSource=data.Result;
                    _this.$refs["basicForm"].clearValidate();
                });
            },
            save: function () {
                var _this = this;
                _this.$refs["basicForm"].validate((valid) => {
                    if (valid) {
                        _this.$ajax.send(
                            'omsapi/tenantserver/save',
                            'post',
                            _this.dataSource,
                            (data) => {
                                _this.dataSource = data.Result;
                                _this.Event.$emit('reloadPageList', data.Result)
                                _this.Utils.messageBox('保存成功.', 'success')
                            }
                        )
                    } else {
                        return false
                    }
                });
            },
            syncVendorEvent:function(row){
                if(row){
                    this.dataSource.VendorName = row.VendorName;
                }
            },
            syncCustomerEvent:function(row){
                if(row){
                    this.dataSource.CustomerName = row.CustomerName;
                }
            },
            syncProductEvent:function(row){
                if(row){
                    this.dataSource.ProductName = row.ProductName;
                }
            },
        },
        components: {
        }
    }
</script>